import { devices } from '~/constants/general'

/**
 *
 *             -----------------------------------------
 *             |                                        |
 *             |              🙀 WARNING 🙀             |
 *             |    This file is being deprecated. Use  |
 *             |     the useScreenSize() composable     |
 *             |                instead.                |
 *             |                                        |
 *             ------------------------------------------
 *
 */

export default {
  mounted() {
    this.mediaQuerySmall = window.matchMedia('(max-width: 768px)')
    this.mediaQueryMedium = window.matchMedia(
      '(min-width: 769px) and (max-width: 1024px)',
    )
    this.mediaQueryNormal = window.matchMedia(
      '(min-width: 1025px) and (max-width: 1280px)',
    )
    this.mediaQueryLarge = window.matchMedia(
      '(min-width: 1281px) and (max-width: 1535px)',
    )
    this.mediaQueryExtraLarge = window.matchMedia('(min-width: 1536px)')
    this.getBreakpoints()
    this.mediaQuerySmall.addEventListener('change', this.getBreakpoints)
    this.mediaQueryMedium.addEventListener('change', this.getBreakpoints)
    this.mediaQueryNormal.addEventListener('change', this.getBreakpoints)
    this.mediaQueryLarge.addEventListener('change', this.getBreakpoints)
    this.mediaQueryExtraLarge.addEventListener('change', this.getBreakpoints)
  },
  data() {
    return {
      activeDevice: devices.DESKTOP,
      mediaQuerySmall: null,
      mediaQueryMedium: null,
      mediaQueryNormal: null,
      mediaQueryLarge: null,
      mediaQueryExtraLarge: null,
    }
  },
  methods: {
    getBreakpoints() {
      if (this.mediaQueryExtraLarge?.matches) {
        this.activeDevice = devices.DESKTOP_BIG
      }
      if (this.mediaQueryLarge?.matches) {
        this.activeDevice = devices.DESKTOP
      }
      if (this.mediaQueryNormal?.matches) {
        this.activeDevice = devices.DESKTOP_SMALL
      }
      if (this.mediaQueryMedium?.matches) {
        this.activeDevice = devices.TABLET
      }
      if (this.mediaQuerySmall?.matches) {
        this.activeDevice = devices.MOBILE
      }
    },

    isBigDesktop() {
      return this.activeDevice === devices.DESKTOP_BIG
    },

    isMobile() {
      return this.activeDevice === devices.MOBILE
    },

    isTablet() {
      return this.activeDevice === devices.TABLET
    },

    isNotDesktop() {
      return this.isMobile() || this.isTablet()
    },
  },

  destroyed() {
    this.mediaQuerySmall.removeEventListener('change', this.getBreakpoints)
    this.mediaQueryMedium.removeEventListener('change', this.getBreakpoints)
    this.mediaQueryNormal.removeEventListener('change', this.getBreakpoints)
    this.mediaQueryLarge.removeEventListener('change', this.getBreakpoints)
    this.mediaQueryExtraLarge.removeEventListener('change', this.getBreakpoints)
  },
}
